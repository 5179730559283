@import 'styles/core/typography';
@import 'styles/utils/shared';

.warningTextContainer {
  display: flex;
  align-items: center;
}

.warning {
  color: var(--color-neutral-01);

  * {
    color: var(--color-neutral-01);
  }
}

.container {
  background-color: var(--color-secondary);
  grid-area: warning;
  padding: 1rem 1.6rem;
  display: flex;
  align-items: center;

  .logo {
    width: 12.8rem;
    height: auto;
    margin-left: 2rem;
  }

  .warning {
    @include body-s-medium;
    margin-left: var(--space-size-2);
  }

  .iconContainer {
    height: var(--space-size-4);
  }
}

.containerWithLink {
  min-height: 6rem;
  display: flex;
  align-items: center;
  background-color: var(--color-secondary);
  padding: var(--space-size-1-5) 2rem;

  @media screen and (min-width: $breakpoint-sm) {
    padding: 2rem var(--space-size-4);
  }

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem var(--space-size-3);
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding: 2rem var(--space-size-5);
  }

  .warningTextContainer {
    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row-reverse;
    }
  }

  .iconContainer {
    height: var(--space-size-2);
  }

  .logo {
    margin-right: var(--space-size-2);
    width: 9.5rem;
    height: auto;

    @media screen and (min-width: $breakpoint-md) {
      margin-right: 0;
    }
  }

  .warning {
    @include body-xs-medium;
    @media screen and (min-width: $breakpoint-md) {
      margin-right: var(--space-size-1);
    }

    @media screen and (min-width: $breakpoint-lg) {
      margin-right: var(--space-size-2);
    }
  }

  .link {
    @include body-xs-medium;
    color: var(--color-neutral-01);
    display: none;

    @media screen and (min-width: $breakpoint-md) {
      display: block;
    }
  }
}
