@import 'styles/core/typography';

.paddingLeft {
  padding-left: 2rem;
  @media screen and (min-width: $breakpoint-md) {
    padding-left: var(--space-size-3);
  }
}

@import 'styles/core/typography';

.container {
  padding-top: var(--space-size-2);
  position: relative;

  @media screen and (min-width: $breakpoint-lg) {
    padding-top: var(--space-size-4);
  }
}

.navigationContainer {
  display: none;
  @media screen and (min-width: $breakpoint-lg) {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    right: var(--space-size-5);
    top: -6.4rem;
  }
}

.container :global(.swiper) {
  overflow-y: visible;
  overflow-x: clip;
}

.topManifestazioneContainer :global(.swiper-slide) {
  width: fit-content;
}

.inEvidenzaContainer :global(.swiper-slide) {
  width: 25.1rem;
  @media screen and (min-width: $breakpoint-md) {
    width: 33rem;
  }
}
// .container :global(.swiper-wrapper.disabled) {
//   transform: translate3d(4rem, 0, 0) !important;
// }
// .container.resetTranslate :global(.swiper-wrapper) {
//   transform: translate3d(2.4rem, 0, 0) !important;
//   @media screen and (min-width: $breakpoint-md) {
//     transform: translate3d(0, 0, 0) !important;
//   }
// }

.container :global(.swiper > .swiper-button-prev) {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    width: var(--space-size-3);
    height: var(--space-size-3);
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    position: absolute;
    top: -3rem;
    right: 7.5rem;
    left: auto;
    z-index: 2;
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: -4rem;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.6 6L6.6 -6.11959e-08L8 1.4L3.4 6L8 10.6L6.6 12L0.6 6Z' fill='%23E96105'/%3E%3C/svg%3E%0A");
    font-size: unset;
  }
}

.container :global(.swiper > .swiper-button-next) {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    width: var(--space-size-3);
    height: var(--space-size-3);
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    position: absolute;
    top: -3rem;
    right: 3.6rem;
    left: auto;
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: -4rem;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.4 6L1.4 12L-6.1196e-08 10.6L4.6 6L-4.63341e-07 1.4L1.4 -6.11959e-08L7.4 6Z' fill='%23E96105'/%3E%3C/svg%3E%0A");
    font-size: unset;
  }
}

.title {
  @include heading-s-medium;
  color: var(--color-on-bg-primary);
  // padding: 0 var(--space-size-3) 1rem;
  // @media screen and (min-width: $breakpoint-md) {
  //   padding: 0 var(--space-size-5) 1rem;
  // }
}

.subtitle {
  @include body-s-medium;
  margin-top: 1.4rem;
  color: var(--color-on-bg-secondary);
}

.classNameWrapper {
  @media screen and (min-width: $breakpoint-md) {
    padding-top: var(--space-size-3);
  }
}

.contentRight {
  display: grid;
  justify-content: end;
  padding: var(--space-size-4) 3.6rem;

  .link {
    @include body-bold;
    color: var(--color-primary);
    cursor: pointer;
    padding: var(--space-size-1);
    text-decoration: unset;
    &:active {
      opacity: 0.5;
    }
  }
}
