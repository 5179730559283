@import 'styles/utils/mixins';
@import 'styles/core/typography.scss';

$delaying: (
  '1': 100,
  '2': 200,
  '3': 300,
  '4': 400,
  '5': 500,
  '6': 600,
  '7': 700,
  '8': 800,
  '9': 900,
  '10': 1000,
);

@mixin generateDelaying($delayMap: $delaying) {
  @each $delay in $delayMap {
    $key: nth($delay, 1);
    $value: nth($delay, 2);

    .delay-#{$key} {
      &::after {
        animation-delay: #{$value}ms;
      }
    }
  }
}
@keyframes loader {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(100%);
  }
}

.container {
  margin-bottom: 2rem;
  padding: 0.8rem 2rem;
  width: 100%;
  animation-name: loader;
  background-color: var(--color-surface-02);
  border-radius: var(--space-size-1);
  position: relative;
  overflow: hidden;

  &::after {
    @include animationLoading(1.5s, 0.5s);
  }

  &.my-account {
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 0.1rem solid var(--color-divider-primary);
  }
}

.noMargin {
  margin: 0;
}

.skeletonMyAccount {
  margin: 0;
  border-radius: 0;
  border-bottom: 0.1rem solid var(--color-divider-primary);
}

.openBets {
  margin-bottom: 0.8rem;
}

.skeletonMyCash {
  margin-top: 2.4rem;
}

@include generateDelaying();
