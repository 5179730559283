@import 'styles/core/_typography.scss';

.title {
  @include label-m-bold;
  color: var(--color-on-bg-primary);
}

.titleContainer {
  margin-top: var(--space-size-3);
  max-width: 12rem;
  height: var(--space-size-4);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.image {
  width: 100%;
  height: 100%;
}

.headingContainer {
  width: 12rem;
  height: 12rem;
  position: relative;
}
