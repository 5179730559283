@import 'styles/utils/shared';
@import 'styles/core/typography';

.container {
  background-color: var(--color-surface-02);
  border-radius: var(--space-size-1);
  display: flex;
  flex-direction: column;
  padding: 0 var(--space-size-1-5);

  @media screen and (min-width: $breakpoint-lg) {
    min-height: 21.2rem;
    padding: 0;
    height: 100%;
  }
}

.description {
  display: block;
  text-align: center;
  color: var(--color-on-bg-primary);
  text-transform: uppercase;
  margin: 0 0 var(--space-size-0-5);
  @include label-xs-medium;

  @media screen and (min-width: $breakpoint-md) {
    text-align: center;
    margin: 0 var(--space-size-1) var(--space-size-1);
  }
}

.paddingLeft {
  padding-left: 2rem;
  @media screen and (min-width: $breakpoint-md) {
    padding-left: var(--space-size-3);
  }
}

.quoteContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.description {
  display: block;
  text-align: center;
  color: var(--color-on-bg-primary);
  text-transform: uppercase;
  margin: 0 0 var(--space-size-0-5);
  @include label-xs-medium;

  @media screen and (min-width: $breakpoint-md) {
    text-align: center;
    margin: 0 var(--space-size-1) var(--space-size-1);
  }
}

.paddingLeft {
  padding-left: 2rem;
  @media screen and (min-width: $breakpoint-md) {
    padding-left: var(--space-size-3);
  }
}

.minWidth {
  min-width: 4.8rem;
  @media screen and (min-width: $breakpoint-md) {
    min-width: 6.7rem;
  }
}
