@import 'styles/core/typography';
@import 'styles/utils/shared';
@import 'styles/utils/mixins';
@import 'styles/core/buttons';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  &::before {
    content: '';
    border-radius: var(--space-size-1);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(45deg, #0000 0% 60%, rgba(0, 0, 0, 60%) 90%);
  }
}

.cover {
  height: 19rem;
  position: relative;
  min-height: 100%;

  margin-bottom: var(--space-size-2);

  @media screen and (min-width: $breakpoint-lg) {
    height: 24.4rem;
  }
}

.imgContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: var(--space-size-1);

  &::before {
    content: '';
    border-radius: var(--space-size-1);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--space-size-1);
  aspect-ratio: 0.8;
}

.number {
  color: var(--color-neutral-01);
  font-weight: var(--text-weight-bold);
  font-size: 5.6rem;
  line-height: lh-unit-less(56, 56);
  letter-spacing: 0.05em;
  position: absolute;
  bottom: -1.4rem;
  z-index: 3;
  left: 0.4rem;
  right: 0.4rem;
  text-shadow: 0rem 0.4rem 1.2rem rgba(0, 0, 0, 0.12);
}

.title {
  margin: var(--space-size-2) var(--space-size-0-5) 0 var(--space-size-0-5);
}

.headerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  z-index: 3;
  padding: var(--space-size-1) var(--space-size-1) 0rem var(--space-size-1);
}

.button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
