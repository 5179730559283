@import 'styles/core/_typography';
@import 'styles/core/_buttons.scss';

.buttonContainer {
  position: relative;

  &.primary {
    @include button-primary-normal;
  }

  &.secondary {
    @include button-secondary-normal;
  }

  &.tertiary {
    @include button-tertiary-normal;
  }
  &.sideMenuUserPrimary {
    @include button-primary;
  }

  &.sideMenuUserTertiary {
    @include button-tertiary;
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.large {
    @include button-normal;
  }
  &.medium {
    @include button-medium;
  }
  &.small {
    @include button-small;
  }
  &.xsmall {
    @include button-xsmall;
  }
}

.spinner {
  position: absolute;
  width: 5rem;
  height: 5rem;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--color-surface-01);
}

.fullWidth {
  width: 100%;

  @media screen and (min-width: $breakpoint-md) {
    width: unset;
  }
}

.childrenContainer {
  opacity: 1;
  &.loading {
    opacity: 0;
  }
}
