@import 'styles/utils/functions';
@import 'styles/core/typography';
@import 'styles/utils/mixins';

.container {
  display: grid;
  grid-template-columns: auto 1.4rem 21.1rem;
  grid-template-rows: 11.2rem;
  width: fit-content;

  @media screen and (min-width: $breakpoint-lg) {
    grid-template-columns: auto 2.2rem 27.8rem;
    grid-template-rows: 15.4rem;
  }
}

.position {
  font-size: 10rem;
  line-height: lh-unit-less(100, 100);
  letter-spacing: 0.05em;
  font-weight: 700;
  -webkit-text-stroke: 0.2rem var(--color-primary);
  color: transparent;
  z-index: -1;
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 16rem;
    line-height: lh-unit-less(160, 160);
  }
}

.card {
  background-color: var(--color-bg);
  border-radius: var(--space-size-1);
  border: 0.1rem solid var(--color-divider-primary);
  position: relative;
  padding: var(--space-size-3) var(--space-size-2);
  grid-column: 2 / span 2;
  grid-row: 1 / span 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 16rem;
    line-height: lh-unit-less(160, 160);
    padding: var(--space-size-3);
  }
}

.name {
  @include body-s-medium;

  color: var(--color-on-bg-primary);
  margin-bottom: var(--space-size-1);
  width: 100%;
  overflow: hidden;
  @include truncate-text(1);

  @media screen and (min-width: $breakpoint-lg) {
    @include truncate-text(2);
  }
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-size-1);

  .amountRight {
    @include label-l-medium;
    display: flex;
    justify-content: flex-end;
  }
}

.textContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  border-top: 0.1rem solid var(--color-divider-primary);
  flex: 1 1 auto;
}

.fullContainer {
  justify-content: space-between;
}

.time {
  @include body-xs-bold;
  color: var(--color-on-bg-primary);
  width: fit-content;
}

.fullAmount {
  @include heading-s-medium;
  color: var(--color-primary);
  flex: 1 0 auto;
  text-align: right;
  @include truncate-text(1);
}
