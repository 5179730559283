@import 'styles/core/typography';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: var(--color-neutral-02);
  justify-content: center;
  align-items: center;
  padding: var(--space-size-1) 0;
}

.number {
  @include label-xs-bold;
  color: var(--color-neutral-01);
  width: var(--space-size-3);
  height: var(--space-size-3);
  border: 0.1rem solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 var(--space-size-0-5);
  transform: scale(1);
  transition: scale 0.3s;

  @media screen and (max-width: $breakpoint-md) {
    height: 2rem;
    width: 2rem;
  }

  &:first-of-type {
    margin: 0 var(--space-size-0-5) 0 0;
    transform: scale(1.25);
    transition: scale 0.3s;
  }
}
