@import 'styles/core/typography';
@import 'styles/core/buttons';
.container {
  min-height: 8.2rem;
  padding: 0.7rem 0;

  @media screen and (min-width: $breakpoint-md) {
    padding: var(--space-size-1) 0 var(--space-size-1-5);
  }
}

.quoteContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.scommessaDescription {
  display: flex;
  justify-content: center;
  @include label-xs-medium;
  margin-bottom: var(--space-size-1);
  color: var(--color-on-bg-primary);
}

.minWidth {
  min-width: 4.8rem;
  @media screen and (min-width: $breakpoint-md) {
    min-width: 6.7rem;
  }
}
