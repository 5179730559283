@import 'styles/core/typography';
.dateAndTimeWrapper {
  display: flex;
  align-items: center;
}
.iconLive {
  height: 2rem;
  width: 2rem;
  object-fit: contain;
  display: block;
  margin-right: 0.4rem;
}
.wrapperLabel {
  text-transform: uppercase;
  display: flex;
  flex-wrap: nowrap;
}
.liveLabel {
  color: var(--color-accent);
  margin-right: 0.4rem;
  @include label-s-bold;
}
.label {
  @include label-s-bold;
  display: block;
  margin: 0 auto;
  color: var(--color-on-bg-secondary);
}
.labelLive {
  @include label-s-bold;
  color: var(--color-on-bg-primary);
}
