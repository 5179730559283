@import 'styles/core/typography';

.container {
  @include label-m-bold;
  color: var(--color-accent);
  background-color: var(--color-bg);
  padding: var(--space-size-1) 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}
