@import 'styles/core/typography';

.marquee {
  @include heading-xxl-light;
  border-bottom: 0.01rem solid var(--color-on-bg-primary);
  border-top: 0.01rem solid var(--color-on-bg-primary);
}

.stripe {
  display: flex;
  margin: 1.1rem 0rem 1.1rem 2.4rem;
  @media screen and (min-width: $breakpoint-lg) {
    margin: 1.9rem 0rem 1.9rem 7.2rem;
  }
}

.stripeTitle {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: var(--space-size-2);
  color: var(--color-on-bg-primary);
  @media screen and (min-width: $breakpoint-lg) {
    margin-right: var(--space-size-3);
  }
}

.stripeContent {
  @include heading-xxl-medium;
  color: var(--color-accent);
  padding-left: var(--space-size-2);
  @media screen and (min-width: $breakpoint-lg) {
    padding-left: var(--space-size-3);
  }
}
