@import 'styles/core/typography';

.paddingLeft {
  padding-left: 2rem;
  @media screen and (min-width: $breakpoint-md) {
    padding-left: var(--space-size-3);
  }
}

@import 'styles/core/typography';

.container {
  padding-top: var(--space-size-2);
  position: relative;

  @media screen and (min-width: $breakpoint-lg) {
    padding-top: var(--space-size-4);
  }
}

.navigationContainer {
  display: none;
  @media screen and (min-width: $breakpoint-lg) {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    right: var(--space-size-5);
    top: -6.4rem;
  }
}

.container :global(.swiper) {
  overflow-y: visible;
  overflow-x: clip;
}

.topManifestazioneContainer :global(.swiper-slide) {
  width: fit-content;
}

.myBonusContainer :global(.swiper-slide) {
  width: 25.1rem;
  @media screen and (min-width: $breakpoint-md) {
    width: 41.5rem;
  }
}

.container :global(.swiper > .swiper-button-prev) {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    width: var(--space-size-3);
    height: var(--space-size-3);
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    position: absolute;
    top: -3rem;
    right: 7.5rem;
    left: auto;
    z-index: 2;
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: -4rem;
  }

  &::after {
    content: var(--arrow-carousel-prev);
    font-size: unset;
  }
}

.container :global(.swiper > .swiper-button-next) {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    width: var(--space-size-3);
    height: var(--space-size-3);
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    position: absolute;
    top: -3rem;
    right: 3.6rem;
    left: auto;
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: -4rem;
  }

  &::after {
    content: var(--arrow-carousel-next);
    font-size: unset;
  }
}

.title {
  @include heading-s-medium;
  color: var(--color-on-bg-primary);
  // padding: 0 var(--space-size-3) 1rem;
  // @media screen and (min-width: $breakpoint-md) {
  //   padding: 0 var(--space-size-5) 1rem;
  // }
}

.subtitle {
  @include body-s-medium;
  margin-top: 1.4rem;
  color: var(--color-on-bg-secondary);
}

.classNameWrapper {
  @media screen and (min-width: $breakpoint-md) {
    padding-top: var(--space-size-3);
  }
}
