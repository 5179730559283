@import 'styles/core/typography';
@import 'styles/core/buttons';
.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &:not(&:last-of-type) {
    margin-right: var(--space-size-1);
  }

  @media screen and (min-width: $breakpoint-md) {
    flex: unset;
  }

  @media screen and (min-width: $breakpoint-lg) {
    &:not(&:last-of-type) {
      margin-right: var(--space-size-2);
    }
  }
}
.buttonQuota {
  cursor: pointer;
  position: relative;
  padding: 0 var(--space-size-0-5);
  background-color: var(--color-surface-01);
  border-radius: var(--space-size-0-5);
  height: 5rem;
  &:hover {
    @media screen and (min-width: $breakpoint-md) {
      color: var(--color-primary);
      transition: color 0.25s ease-in;
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      color: var(--color-on-bg-primary);
    }
  }

  &.active {
    background-color: var(--color-accent);
    color: var(--color-surface-01);
    transition: all 0.25s ease-in;
    span {
      color: var(--color-secondary);
      transition: all 0.2s;
    }
    &:hover {
      @media screen and (min-width: $breakpoint-md) {
        color: var(--color-primary);
        transition: color 0.25s ease-in;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    height: 5.4rem;
  }
}

.quotaWrapper {
  display: flex;
  min-height: var(--space-size-1);
  align-items: center;
  flex-direction: column;

  &:before {
    width: var(--space-size-1);
    height: var(--space-size-1);
    content: ' ';
    border-left: var(--space-size-0-5) solid transparent;
    border-right: var(--space-size-0-5) solid transparent;
    border-bottom: var(--space-size-1) solid transparent;
    margin-bottom: 4px;
  }
  &:after {
    width: var(--space-size-1);
    height: var(--space-size-1);
    content: ' ';
    border-left: var(--space-size-0-5) solid transparent;
    border-right: var(--space-size-0-5) solid transparent;
    border-top: var(--space-size-1) solid transparent;
  }

  &.changed {
    &.increasing:before {
      border-bottom: var(--space-size-1) solid #55d470;
    }
    &.decreasing:after {
      border-top: var(--space-size-1) solid #f05d5d;
    }
  }
}

.label {
  align-self: center;
  @include label-xs-bold;
  margin-bottom: 0.2rem;
  color: var(--color-on-bg-secondary);
}

.noAction {
  &:disabled {
    opacity: unset;
    cursor: not-allowed;
    &:hover {
      color: var(--color-on-bg-primary);
    }
  }
}
