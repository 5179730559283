@import 'styles/utils/mixins';
@import 'styles/utils/shared';
@import 'styles/core/typography';

.container {
  padding: var(--space-size-5) 0;
  @media screen and (min-width: 39.5em) {
    padding: var(--space-size-5) 2rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    @include grid;
    padding: var(--space-size-10) var(--space-size-5);
  }
}

.textContainer {
  text-align: center;
  grid-column: span 6;
  margin-bottom: var(--space-size-2);
  padding: 0 2rem;
  @media screen and (min-width: $breakpoint-sm) {
    padding: 0;
  }

  @media screen and (min-width: $breakpoint-md) {
    grid-column: 3 / span 8;
    margin-bottom: var(--space-size-4);
  }

  @media screen and (min-width: $breakpoint-lg) {
    grid-column: 4 / span 6;
  }
}

.title {
  @include heading-xl-medium;
  color: var(--color-on-bg-primary);
}

.subtitle {
  @include body-medium;
  color: var(--color-on-bg-secondary);
  margin-top: var(--space-size-1);
  @media screen and (min-width: $breakpoint-md) {
    margin-top: var(--space-size-2);
  }
}

.mainBox {
  margin: 0 2rem;
  @media screen and (min-width: 39.5em) {
    margin: 0;
  }
  @media screen and (min-width: $breakpoint-md) {
    grid-column: 1 / span 6;
    height: 13.4rem;
  }
}

.listBoxContainer {
  display: inline-flex;
  margin-top: var(--space-size-2);
  @media screen and (min-width: 39.5em) {
    width: 100%;
  }
  @media screen and (min-width: $breakpoint-md) {
    margin-top: 0;
  }
}

.listBoxWrapper {
  overflow-x: auto;
  margin-left: 2rem;
  @media screen and (min-width: 39.5em) {
    margin-left: 0;
  }
  @media screen and (min-width: $breakpoint-md) {
    grid-column: 7 / span 6;
  }
}

.box {
  @include label-l-bold;
  width: 20rem;
  margin-right: 2rem;
  @media screen and (min-width: 39.5em) {
    flex: 1 1 50%;
    height: 13.4rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin-right: var(--space-size-3);
  }
}
