@import 'styles/core/typography';

.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  transform: translateY(-100%);
  background-color: var(--color-accent);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-size-1-5);
  transition: all 0.5s ease;

  &.scrollDown {
    transform: translateY(0);
  }

  @media screen and (min-width: $breakpoint-md) {
    padding: var(--space-size-1-5) var(--space-size-5);
  }
}

.textContainer {
  display: flex;
  align-items: center;
}

.titleDesktop {
  @include body-bold;
  color: var(--color-surface-01);
  display: none;
  @media screen and (min-width: $breakpoint-md) {
    display: block;
  }
}

.titleMobile {
  @include body-bold;
  color: var(--color-surface-01);
  @media screen and (min-width: $breakpoint-md) {
    display: none;
  }
}

.subtitle {
  @include body-s-medium;
  display: none;
  color: var(--color-surface-01);
  margin-top: 0.2rem;
  @media screen and (min-width: $breakpoint-md) {
    display: block;
  }
}

.icon {
  display: none;
  width: 3.4rem;
  height: 3rem;
  margin-right: var(--space-size-2);
  @media screen and (min-width: $breakpoint-md) {
    display: block;
  }
}

.cta {
  @include body-s-bold;
  display: none;
  color: var(--color-surface-01);
  text-decoration: none;
  margin-right: 1.8rem;
  @media screen and (min-width: $breakpoint-md) {
    display: inline;
  }
}
