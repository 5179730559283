@import 'styles/core/typography';
@import 'styles/utils/mixins';

.bodyContainer {
  padding: var(--space-size-1) 0;
  border-bottom: var(--divider-primary);
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;

  text-decoration: none;
  color: var(--color-on-bg-primary);
  cursor: pointer;

  @media screen and (min-width: $breakpoint-md) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: var(--space-size-2) var(--space-size-1);
    flex: 1 1 auto;
    height: auto;
  }
}
.bodyContainerCenter {
  justify-content: center;
  align-items: center;
}
.imageContainer {
  width: 2rem;
  height: 2.2rem;
  position: relative;
  flex: 0 0 2rem;
  overflow: hidden;

  @media screen and (min-width: $breakpoint-md) {
    width: 2.6rem;
    height: 2.8rem;
    flex: 0 0 2.6rem;
  }
}
.image {
  width: 1.8rem;
  object-fit: cover;
}
.label {
  margin: 0.2rem 0;
  text-transform: capitalize;
  text-align: center;
  @include truncate-text(2);
  @include body-s-medium;
}
.wrapperCompetitor {
  display: flex;

  @media screen and (min-width: $breakpoint-md) {
    min-height: var(--space-size-6);
    justify-content: center;
  }
}
.multipleCompetitor {
  flex-direction: row;
  align-items: center;

  &:not(&:last-of-type) {
    margin-bottom: var(--space-size-0-5);
  }
  .imageContainer {
    margin-right: var(--space-size-1);
  }

  @media screen and (min-width: $breakpoint-md) {
    margin-bottom: 0rem;
    flex: 0 1 48%;
    &:not(&:last-of-type) {
      margin-bottom: 0rem;
    }
    flex-direction: column;
    align-items: center;
    .imageContainer {
      margin-right: 0;
    }
  }
}
.singleCompetitor {
  flex-direction: row;
  justify-content: center;
  .imageContainer {
    margin-right: var(--space-size-2);
  }
}

.score {
  position: absolute;
  z-index: 2;
  margin: auto;
  color: var(--color-accent);
  @include body-bold;
  display: flex;
  flex-direction: column;
  top: var(--space-size-1);
  right: 1.2rem;
  bottom: var(--space-size-1);
  justify-content: space-around;
  @media screen and (min-width: $breakpoint-md) {
    position: static;
    flex-direction: row;
    right: unset;
  }
}
.scoreCenter {
  justify-content: center;
}

// [data-env='happybet'] {
//   .label {
//     @media screen and (min-width: $breakpoint-md) {
//       font-weight: 600;
//     }
//   }
// }
