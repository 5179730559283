@import 'styles/core/typography';

.container {
  padding: var(--space-size-1) var(--space-size-2);
  background-color: var(--color-neutral-02);
  border-radius: var(--space-size-1) 0 var(--space-size-1) 0;
  color: var(--color-neutral-01);
  width: fit-content;
  @include label-s-bold;
  text-transform: uppercase;
  visibility: visible;
}

.hide {
  visibility: hidden;
}
