@import 'styles/utils/shared';

.container {
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  background-color: var(--color-surface-02);
  padding: var(--space-size-3) var(--space-size-2);
  border-radius: var(--space-size-1);

  &.mainBox {
    padding: var(--space-size-3);

    .text {
      margin-top: 2rem;
    }

    .icon {
      width: 11.9rem;
      height: 4rem;
      padding: var(--space-size-1);
      margin-right: auto;
      margin-left: auto;
    }

    @media screen and (min-width: $breakpoint-md) {
      padding: var(--space-size-2) var(--space-size-3);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    padding: var(--space-size-2);
  }
}

.text {
  margin-top: var(--space-size-2);
  color: var(--color-on-bg-primary);
}

.icon {
  width: var(--space-size-4);
  height: var(--space-size-4);
  margin-right: auto;
  margin-left: auto;
}
