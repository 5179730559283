@import 'styles/utils/shared';

.container {
  border-radius: var(--space-size-1);
  min-height: 19rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 var(--space-size-1);
  height: auto;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  z-index: 0;
  aspect-ratio: 0.8;
}
