@import 'styles/core/typography';

.container {
  cursor: pointer;
  text-decoration: none;
  min-height: var(--space-size-5);
  margin: var(--space-size-0-5) 0;

  @media screen and (min-width: $breakpoint-md) {
    //min-height: var(--space-size-8);
    margin: 0 0 0 var(--space-size-1);
    padding: var(--space-size-1) 0;
  }

  &.full {
    .list {
      max-width: calc(100vw - 1.6rem);
      margin: 0;

      @media screen and (min-width: $breakpoint-md) {
        max-width: unset;
      }
    }
  }
}

.link {
  display: flex;
  flex-direction: column;
}

.list {
  margin: 0 var(--space-size-1) 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // max-width: 18rem;
  // min-width: auto;

  @media screen and (min-width: $breakpoint-sm) {
    max-width: 45rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    margin: 0;
    max-width: unset;
  }
}
.singleItem {
  justify-content: center;
}
.item {
  display: flex;
  flex-wrap: nowrap;
  height: fit-content;
  width: 100%;

  @media screen and (min-width: $breakpoint-md) {
    margin: 0;
    max-width: unset;
    padding: 0.1rem 0;
    //align-items: center;
  }

  &:last-of-type {
    margin: 0;
  }
}

.name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  @include body-xs-bold;
  color: var(--color-on-bg-primary);
  font-family: var(--font-family-roboto);
  font-size: var(--space-size-1-5);
  margin: 0;
  line-height: 1.7;

  @media screen and (min-width: $breakpoint-md) {
    max-width: unset;
    width: 100%;
  }

  &.withCard {
    max-width: calc(100% - 1.8rem);
    margin: 0 var(--space-size-0-5) 0 0;

    @media screen and (min-width: $breakpoint-md) {
      width: calc(100% - 1.8rem);
    }
  }
}

.imageContainer {
  position: relative;
  width: var(--space-size-1-5);
  height: var(--space-size-1-5);
  margin: 0 var(--space-size-0-5) 0 0;

  @media screen and (min-width: $breakpoint-md) {
    width: var(--space-size-2);
  }
}

.image {
  width: 100%;
  height: 100%;
}

.redCart {
  @include label-xs-bold;
  color: var(--color-bg);
  padding: 0.1rem 0;
  border-radius: 0.1rem;
  background-color: var(--color-warning);
  height: 1.4rem;
  min-width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.skeleton {
  margin: 0;
}

// [data-env='happybet'] {
//   .container {
//     @media screen and (min-width: $breakpoint-md) {
//       min-height: 4rem;
//       padding: 0;
//       margin: 1rem var(--space-size-1) 1rem var(--space-size-1);
//     }
//   }

//   .name {
//     @media screen and (min-width: $breakpoint-md) {
//       font-weight: 600;
//     }
//   }

//   .list {
//     @media screen and (min-width: $breakpoint-md) {
//       margin: 0;
//     }
//   }
// }
