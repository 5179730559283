@import 'styles/core/typography';

.container {
  background-color: var(--color-surface-02);
  border-radius: var(--space-size-1);
  min-height: 19rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 var(--space-size-1);
  height: auto;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  z-index: 0;
  aspect-ratio: 0.8;

  .wrapper {
    width: 100%;
    height: 100%;
    border-radius: var(--space-size-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    .imageContainer {
      height: var(--space-size-3);
      width: var(--space-size-3);
      position: relative;
      margin-right: var(--space-size-1);
      border-radius: 50%;
      .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .name {
      @include heading-s-bold;
      color: var(--color-on-bg-primary);
    }
  }
}
