@import 'styles/core/_typography.scss';

.container {
  aspect-ratio: 0.6;
  //   width: 24rem;
  flex: 0 0 24rem;
  padding: 2rem var(--space-size-3);
  border-radius: var(--space-size-2);
  position: relative;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;

  @media screen and (min-width: $breakpoint-md) {
    aspect-ratio: 0.94;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.9) 18.98%, rgba(0, 0, 0, 0) 62.84%);
    border-radius: var(--space-size-2);
  }
  &.active {
    outline: 0.4rem solid var(--color-accent);
  }
  &.inactive {
    outline: 0rem;
  }
}

.type {
  @include label-s-bold;
  text-transform: uppercase;
  color: var(--color-neutral-01);
  padding: 0.5rem var(--space-size-1-5);
  background-color: var(--color-neutral-02);
  border: 0.1rem solid var(--color-neutral-01);
  border-top: unset;
  border-radius: 0 0 0.4rem 0.4rem;
  box-shadow: 0px 0.2rem 0.2rem var(--color-shadow);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.textContainer {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-size-1);
  width: 100%;
  @media screen and (min-width: $breakpoint-md) {
    width: unset;
  }
}

.linkContainer {
  width: 100%;
  @media screen and (min-width: $breakpoint-md) {
    width: unset;
  }
}

.button {
  cursor: pointer;
  height: var(--space-size-2);
}

.icon {
  width: var(--space-size-2);
  height: var(--space-size-2);
}

.text {
  @include heading-s-bold;
  color: var(--color-neutral-01);
  text-align: left;
}

.subtitle {
  @include body-s-medium;
  color: var(--color-on-bg-secondary);
  text-align: left;
  margin-bottom: var(--space-size-1);
}

.link {
  @include label-m-bold;
  color: var(--color-neutral-02);
  text-decoration: unset;
  padding: 1.2rem 3.2rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.4rem;
}
