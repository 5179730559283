@import 'styles/utils/shared';

.paddingRight {
  padding-right: calc(var(--space-size-2) + var(--space-size-0-5));

  @media screen and (min-width: $breakpoint-sm) {
    padding-right: var(--space-size-4);
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding-right: var(--space-size-5);
  }
}

.paddingLeft {
  padding-left: calc(var(--space-size-2) + var(--space-size-0-5));

  @media screen and (min-width: $breakpoint-sm) {
    padding-left: var(--space-size-4);
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding-left: var(--space-size-5);
  }
}

.paddingTop {
  padding-top: var(--space-size-4);

  @media screen and (min-width: $breakpoint-xl) {
    padding-top: var(--space-size-5);
  }
}

.paddingBottom {
  padding-bottom: var(--space-size-4);

  @media screen and (min-width: $breakpoint-xl) {
    padding-bottom: var(--space-size-5);
  }
}

.customPaddingBonusPage {
  padding-bottom: var(--space-size-2);
}

.bgColor {
  background-color: var(--color-surface-01);
}

.borderRadius {
  border-radius: 8px 8px 0 0;
}
.allBorderRadius {
  border-radius: 8px;
}
.wrapperFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid var(--color-divider-primary);
  padding-right: var(--space-size-2);

  @media screen and (min-width: $breakpoint-sm) {
    padding-right: var(--space-size-4);
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding-right: var(--space-size-5);
  }
}

.endFlex {
  display: flex;
  justify-content: flex-end;
}

.containerMessage {
  height: 64.4rem;
  overflow-y: scroll;
}

.backgroundElement {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    background: var(--color-surface-01);
    width: 100%;
    height: 20.8rem;
    top: 0;
    z-index: -1;
  }
}

.backgroundElementWithMargin {
  position: relative;
  margin-top: var(--space-size-4);

  @media screen and (min-width: $breakpoint-xl) {
    margin-top: var(--space-size-5);
  }
  &::after {
    content: '';
    position: absolute;
    background: var(--color-surface-01);
    width: 100%;
    height: 20.8rem;
    top: 0;
    z-index: -1;
  }
}

.ippicaBackground {
  position: relative;
  padding-top: var(--space-size-3);
  &::after {
    content: '';
    position: absolute;
    background: var(--color-surface-01);
    width: 100%;
    height: 15.2rem;
    top: 0;
    z-index: -1;
  }
}
.ippicaBackgroundNoContent {
  background: var(--color-surface-01);
  border-radius: var(--space-size-1);
  @media screen and (min-width: $breakpoint-md) {
    margin-right: var(--space-size-4);
  }
  @media screen and (min-width: $breakpoint-lg) {
    margin-right: var(--space-size-5);
  }
  [class^='IppicaEmptyState_container'] {
    margin-right: 0;
  }
}
.ippicaBackground {
  background: var(--color-surface-01);
  border-radius: var(--space-size-1);
}
.myBonusBackgroundElement {
  position: relative;
}

.fullWidth {
  width: 100%;
}

.openBets {
  border-radius: 0.8rem;
  margin-top: 2.4rem;
}

.marginFavourites {
  margin-top: var(--space-size-2);
  margin-bottom: var(--space-size-2);

  @media screen and (min-width: $breakpoint-lg) {
    margin-top: var(--space-size-4);
    margin-bottom: var(--space-size-4);
  }
}

.widgetIframeContainer {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.hasMarginTop {
  @media screen and (min-width: $breakpoint-md) {
    margin: var(--space-size-2) 0 0 0;
  }
}
