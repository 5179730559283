@import 'styles/utils/mixins';
@import 'styles/utils/shared';
@import 'styles/core/typography';

.container {
  position: relative;
  height: 22.4rem;
  display: flex;
  border-radius: var(--space-size-1);
  overflow: hidden;
  margin: 0 2rem;

  &::after {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 100%;
    @media screen and (min-width: $breakpoint-md) {
      background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    @include grid;
    border-radius: var(--space-size-2);
    height: 28.9rem;
    margin: 0 var(--space-size-5);
  }
}

.textContainer {
  position: relative;
  z-index: 1;
  grid-column: span 6;
  text-align: center;
  align-self: flex-end;
  padding: 0 var(--space-size-2);
  margin-bottom: var(--space-size-1-5);
  @media screen and (min-width: $breakpoint-md) {
    grid-column: 8 / span 4;
    text-align: left;
    padding: 0;
    margin-bottom: var(--space-size-5);
  }
}

.text {
  @include heading-s-medium;
  color: var(--color-neutral-01);
}

.button {
  display: inline-block;
  margin-top: var(--space-size-1-5);
  @media screen and (min-width: $breakpoint-md) {
    margin-top: var(--space-size-3);
  }
}
