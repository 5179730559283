@import 'styles/core/typography';

.container {
  background-color: var(--color-surface-02);
  width: 100%;
  height: 100%;
  border-radius: var(--space-size-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.logo {
  transform: scale(270%);
  object-position: 50% 37%;
}
