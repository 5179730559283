@import 'styles/core/typography';

.container {
  @include body-s-medium;

  > * {
    @include body-s-medium;
    line-height: lh-unit-less(14, 26);
    margin: 0 0 var(--space-size-1);
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ol {
    list-style: decimal;
    list-style-position: inside;
  }

  li {
    margin: 0;
  }

  h1 {
    @include heading-xxl;
    font-weight: 400;
  }

  h2 {
    @include heading-xl;
    font-weight: 400;
  }

  h3 {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: lh-unit-less(18, 32);
    letter-spacing: 1.08px;
  }

  h4 {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: lh-unit-less(16, 32);
    letter-spacing: 0.96px;
  }

  h5,
  h6 {
    @include heading-s;
    font-weight: 400;
  }

  a {
    // text-decoration: underline;
    color: var(--color-on-bg-primary);
    @include body-s-medium;
    line-height: lh-unit-less(14, 26);
    font-weight: 700;
  }
}
