@import 'styles/core/typography';
.headerContainer {
  padding: 1.1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: var(--divider-primary);

  @media screen and (min-width: $breakpoint-md) {
    padding: var(--space-size-2);
  }
}

.iconDisciplina {
  height: var(--space-size-2);
  width: var(--space-size-2);
  object-fit: cover;
  display: block;
  margin-right: var(--space-size-1);
}

.wrapperManifestazioneLabel {
  display: flex;
  align-items: center;

  .label {
    @include label-s-medium;
    text-decoration: none;
    color: var(--color-on-bg-primary);
    cursor: pointer;
  }
}
