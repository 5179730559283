@import 'styles/core/typography';
@import 'styles/utils/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
}
.linkWrapper {
  min-height: 19rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 var(--space-size-1);
  height: auto;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  z-index: 0;
  aspect-ratio: 0.8;
  color: var(--color-neutral-01);
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  border-radius: var(--space-size-1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
}
.image {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--space-size-1);
}
.textContent {
  @include truncate-text(2);
  @include body-xs-medium;
  color: var(--color-on-bg-secondary);
}

.title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 4;
  margin: 0 auto;
  text-align: center;
  max-width: calc(100% - var(--space-size-8));
  transform: translateY(-50%);
  @include truncate-text(2);
  @include heading-s-bold;
}
